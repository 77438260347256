import React from 'react';
import PropTypes from 'prop-types';

const Video = ({videoUrl, title='Video Resource', ...props}) => {

  return (
    <div className="w-full md:h-60 lg:h-96" {...props}>
      <iframe
        width="100%"
        height="100%"
        title={title}
        src={`https://www.youtube.com/embed/${videoUrl}?modestbranding=1&rel=0&cc_load_policy=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    </div>
  );
};

Video.propTypes = {
  videoUrl: PropTypes.string,
}

Video.defaultProps = {
  videoUrl: '',
}

export default Video;