import React from 'react';
import PropTypes from 'prop-types';

import connections_logo from "./connections_logo.svg";

const ExternalResources = ({externalResources, ...props}) => {

  // externalResource => {title:string, icon:imageurl, url:url}
  const resourceList = externalResources.map( (resource, resourceIndex) => {
    let classProperties;
    if (externalResources.length === 1) {
      classProperties = 'rounded-md';
    }
    else {
      if (resourceIndex === 0) {
        classProperties = 'rounded-t-md mb-0p5';
      }
      else if (resourceIndex+1 === externalResources.length) {
        classProperties = 'rounded-b-md';
      }
      else {
        classProperties = 'mb-0p5';
      }
    }


    return (
      <li key={resource.name}>
        <a target="_blank" rel="noreferrer" href={resource.link} className='cursor-pointer group'>
          <div className={`${classProperties} flex justify-between items-center h-14 p-3 pr-5 bg-gray-100`}>
            <div className="flex items-center">
              <div className='bg-white h-9 mr-3 p-1 rounded-full flex items-center'>
                <img
                  className='w-7'
                  src={connections_logo}
                  alt="ncsa connections web link"
                />
              </div>
              <p className='uppercase text-gray-800 text-xs font-bold'>{resource.name}</p>
            </div>
            <svg className="text-gray-400 group-hover:text-gray-500" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6L1 11" stroke="currentColor" strokeWidth="1.8"/>
            </svg>
          </div>
        </a>
      </li>
    );
  });

  
  return (
    resourceList.length === 0 ?
    <></> : 
    <div {...props}>
      <p className='uppercase text-sm text-gray-400 font-bold mb-2'>Web Links</p>
      <ul>
        {resourceList}
      </ul>
    </div>
  );
}

ExternalResources.propTypes = {
  externalResources: PropTypes.array,
}

ExternalResources.defaultProps = {
  externalResources: [],
}

export default ExternalResources;