// create the route example.com/resources/resource-id
// this will dynamicaly create the resource pages
import React, { Fragment } from 'react';
import { graphql } from "gatsby";

import DetailScreen from 'components/screens/DetailScreen/DetailScreen';

// In theory we could pass in props that we'd fetched via Gatsby's GraphQL
export default ({ data }) => (
  <Fragment>
    <DetailScreen data={data}/>
  </Fragment>
);

export const query = graphql`
query resourceDetail($strapiId: Int) {
  strapiResource(strapiId: {eq: $strapiId}) {
    topic {
      Name
      Background {
        publicURL
      }
    }
    name
    video
    file {
      publicURL
      name
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: WEBP, quality: 80)
      }
    }
    time_description
    type
    subtitle
    description
    external_resources {
      name
      link
    }
    resources {
      name
      time_description
      type
      file {
        publicURL
        childImageSharp {
          fluid(quality: 70, maxWidth: 1240) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image_story_thumbnail {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1240) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      strapiId: id
      topic {
        Name
      }
    }
  }
}
`;
