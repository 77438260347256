import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';
import { Slide } from 'react-slideshow-image';
import './ImageSlide.css';

const ImageSlide = ({images, ...props}) => {
  if (!(images instanceof Array)) {images = [images]}

  const ArrowLeft = <button className="nav-arrow" data-type="next" aria-label="Next Slide">
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.63604 5.27501e-05L8.05025 1.41427L3.10051 6.36401L8.05025 11.3138L6.63604 12.728L1.68629 7.77823L0.272078 6.36401L1.68629 4.9498L6.63604 5.27501e-05Z" fill="#EC1C24"/>
    </svg>

  </button>;

  const rightArrow = <button className="nav-arrow" data-type="next" aria-label="Next Slide">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36396 5.27501e-05L4.94975 1.41427L9.89949 6.36401L4.94975 11.3138L6.36396 12.728L11.3137 7.77823L12.7279 6.36401L11.3137 4.9498L6.36396 5.27501e-05Z" fill="#EC1C24"/>
      </svg>
    </button>;

  const createIndicator = i => <div className="h-2.5 w-2.5 rounded-full bg-white mr-2 opacity-60 cursor-pointer indicator"></div>;

  const slideProperties = {
    duration: 0,
    autoplay: false,
    transitionDuration: 200,
    infinite: true,
    easing: "ease",
    indicators: images.length < 2 ? false : createIndicator,
    arrows: images.length < 2 ? false : true,
    nextArrow: rightArrow,
    prevArrow: ArrowLeft,
  };

  // images => [{url:url, title:string}]
  const imageList = images.map((image, imageIndex) => {
    let gatsbyImage = image ? getImage(image) : null;
    return (
      image ? 
      <div key={`${image.name}-${imageIndex}`} className="each-slide">
        <GatsbyImage image={gatsbyImage} alt={image.name} loading="eager" className='w-full' />
      </div> :
      <> </>
    );
  });

  return (
    <div className="slide-container" {...props} >
      <Slide {...slideProperties}>
        {imageList}
      </Slide>
    </div>
  );
};

ImageSlide.propType = {
  images: PropTypes.array,
}

ImageSlide.defaultProps = {
  images: [],
}

export default ImageSlide;