import React from 'react';
import PropTypes from 'prop-types';

const ResourceHeader = ({name, category, background, ...props}) => {

  return (
      <div
        className={`bg-gray-400 rounded-t-lg px-6 py-5 md:px-8 md:py-7 mb-0p5 h-48 flex flex-col justify-end items-start bg-cover bg-center`}
        {...props}
        style={{
          ...(background && {backgroundImage: `url(${background.publicURL})`}),
        }}
      >
        <p className='text-black text-xs md:text-sm mb-0 uppercase'>{category}</p>
        <h1 className='text-black font-extrabold font-heading text-2xl md:text-3xl uppercase'>{name}</h1>
      </div>
  );
};

ResourceHeader.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  background: PropTypes.object,
};

ResourceHeader.defaultProps = {
  name: null,
  category: null,
  background: null,
}

export default ResourceHeader;