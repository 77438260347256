import React from 'react';
import PropTypes from 'prop-types';

import MarkdownContainer from 'components/elements/MarkdownContainer/MarkdownContainer'

const ResourceContent = ({subtitle, description, type, time_description, ...props}) => {
  
  return (
    <article {...props} >
      <p className='text-sm text-gray-400 mb-2'>{`${time_description ? time_description : 'Unknown Length'} - ${type ? type : 'General'}`}</p>

      <h2 className='font-bold text-xl mb-3 text-gray-800'>{subtitle}</h2>

      <MarkdownContainer content={description} />
    </article>
  );
};

ResourceContent.propTypes  = {
  subtitle: PropTypes.string,
  description: PropTypes.string,
  time_description: PropTypes.string,
  type: PropTypes.string,
}

ResourceContent.defaultProps = {
  subtitle: null,
  description: null,
  time_description: null,
  type: null,
}

export default ResourceContent;