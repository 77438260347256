import React from 'react';
import { Helmet } from 'react-helmet';

import Container from "components/elements/Container/Container";
import ResourceHeader from 'components/elements/ResourceHeader/ResourceHeader';
import ImageSlide from 'components/elements/ImageSlide/ImageSlide';
import ResourceContent from 'components/elements/ResourceContent/ResourceContent';
import ExternalResources from 'components/elements/ExternalResources/ExternalResources';
import ResourceSlide from 'components/elements/ResourceSlide/ResourceSlide';
import Video from '../../elements/Video/Video';


const DetailScreen = ({data}) => {

  // creating a new resources->node->resource mapping due to nesting differences for query results
  // some components get resorces{node{resource}} others resources{resource} from graphql
  const node_resources = data.strapiResource.resources ?
    data.strapiResource.resources.map((resource_item) => {
      return {'node': resource_item};
    }) :
    [];

  // insert this resource's topic image for use as external resource icons
  const external_resources = data.strapiResource.external_resources ? 
    data.strapiResource.external_resources.map((ext_resource) => {
      ext_resource.image = data.strapiResource.topic.Background;
      return ext_resource;
    }) :
    [];


  return (
    <>
      <Helmet
        title={data.strapiResource.name}
        />
      <Container style={{ ...(data?.strapiResource?.type?.toLowerCase() === 'image story' && {maxWidth: '32.5rem'}) }}>
        <ResourceHeader name={data.strapiResource.name} category={data.strapiResource.topic.Name} background={data.strapiResource.topic.Background}/>
        { data.strapiResource.type && data.strapiResource.type.toLowerCase() === 'video' ?
            <Video videoUrl={data.strapiResource.video} title={data.strapiResource.name} /> :
            <ImageSlide images={data.strapiResource.file} />}
        <div className="max-w-lg mx-auto mt-8 mb-20 space-y-12">
            <ResourceContent subtitle={data.strapiResource.subtitle} description={data.strapiResource.description} type={data.strapiResource.type} time_description={data.strapiResource.time_description} />
            <ExternalResources externalResources={external_resources}/>
        </div>
        <ResourceSlide title="Related Resources" resources={node_resources} extraMargin={false} />
      </Container>
    </>
  );
};


export default DetailScreen;